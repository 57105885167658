<template>
  <template v-if="!isLoading">
    <view-discount-details
      v-if="isViewMode && discountDetails"
      :discountDetails="discountDetails"
      :paidYn="paidYn"
      :priceList="priceList"
      :defaultPrice="defaultPrice"
      :exchangeRates="exchangeRates"
    />
    <discount-details-form
      v-else
      :discountDetails="discountDetails"
      :paidYn="paidYn"
      :priceList="priceList"
      :defaultPrice="defaultPrice"
      :exchangeRates="exchangeRates"
    />
  </template>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { fetchProductDiscountApi } from '@/apis/product-discount.api';
import { fetchProductSellingPriceApi } from '@/apis/product-selling-price.api';
import DiscountDetailsForm from '@/components/product-discount/discount-details-form.vue';
import ViewDiscountDetails from '@/components/product-discount/view-discount-details.vue';
import { showAlert } from '@/composables/useDialog';
import { currencyCodes } from '@/constants/currency-codes.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { PRODUCT_HOME_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { useCurrencyStore } from '@/stores/currency.store';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductDiscountDetailResponse } from '@/types/product-discount/product-discount-response.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';
import { redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';

const { t } = useI18n();
const route = useRoute();
const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const productId = Number(route.params.productId);

const isDetailsMode = route.params.discountId;

const isLoading = ref(true);
const isViewMode = ref(false);
const discountDetails = ref<ProductDiscountDetailResponse>();
const paidYn = ref(false);
const priceList = ref<PriceListType[]>([]);
const defaultPrice = ref(0);
const adminTitle = ref(t('studio.prj_prod.this_prod.prod_mgmt_discount_register_title'));
const exchangeRates = ref<CurrencyInfoModel[]>();
const isCheckLeave = ref<boolean>(true);

const showErrorAndRedirectHome = async () => {
  await showAlert({
    content: t(COMMON_ERROR_MESSAGE_KEY),
    severity: 'info',
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    confirmClasses: '!w-full !max-w-full'
  });
  isCheckLeave.value = false;
  redirectTo(PRODUCT_HOME_PAGE_URL);
};

const init = async () => {
  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);
  const sellingPriceRes = await fetchProductSellingPriceApi(productId);

  const correctPriceOrder = [
    CurrencyCode.Usd,
    CurrencyCode.Krw,
    CurrencyCode.Cny,
    CurrencyCode.Eur,
    CurrencyCode.Gbp,
    CurrencyCode.Hkd,
    CurrencyCode.Idr,
    CurrencyCode.Jpy,
    CurrencyCode.Myr,
    CurrencyCode.Php,
    CurrencyCode.Sgd,
    CurrencyCode.Thb,
    CurrencyCode.Vnd
  ];

  if (sellingPriceRes?.verifyPassedPrice) {
    sellingPriceRes.verifyPassedPrice.priceList = sellingPriceRes.verifyPassedPrice.priceList.sort(
      (a: PriceListType, b: PriceListType) => {
        return (
          correctPriceOrder.indexOf(a.displayCurrencyCode as CurrencyCode) -
          correctPriceOrder.indexOf(b.displayCurrencyCode as CurrencyCode)
        );
      }
    );

    paidYn.value = sellingPriceRes?.verifyPassedPrice?.paidYn === Confirmation.YES;
    priceList.value = sellingPriceRes?.verifyPassedPrice.priceList ?? [];
    defaultPrice.value = sellingPriceRes?.verifyPassedPrice.defaultPrice ?? 0;

    if (isDetailsMode) {
      adminTitle.value = t(
        'studio.prj_prod.this_prod.prod_mgmt_discount_this_discount_details_title'
      );
      const discountId = route.params.discountId as string;
      if (!discountId) {
        return;
      }
      const res = await fetchProductDiscountApi(productId, Number(discountId));

      if (res) {
        discountDetails.value = res;
        const startDate = DateTime.fromMillis(res.startAt);
        const currentDate = DateTime.local();
        isViewMode.value = startDate <= currentDate;
      } else {
        showErrorAndRedirectHome();
        return;
      }
    }
  } else {
    showErrorAndRedirectHome();
    return;
  }

  isLoading.value = false;
};

init();

onBeforeRouteLeave(async () => {
  if (isViewMode.value) {
    return;
  }
  if (isCheckLeave.value) {
    return showConfirmLeaveDialog();
  }
});
</script>
