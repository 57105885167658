<template>
  <st-box>
    <dl class="flex flex-col gap-20">
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_name_title') }}
        </dt>
        <dd>
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
            discountDetails.discountName
          }}</span>
        </dd>
      </div>
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_start_date_title') }}
        </dt>
        <dd class="flex gap-4 items-center">
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
            formatDateTime(discountDetails.startAt, DATE_TIME_FORMAT_WITH_DOT)
          }}</span>
          <span class="text-sm font-medium leading-md text-on-surface-elevation-4">({{ utcTimezone }})</span>
        </dd>
      </div>
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_end_date_title') }}
        </dt>
        <dd class="flex gap-4 items-center">
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
            formatDateTime(discountDetails.endAt, DATE_TIME_FORMAT_WITH_DOT)
          }}</span>
          <span class="text-sm font-medium leading-md text-on-surface-elevation-4">({{ utcTimezone }})</span>
        </dd>
      </div>
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_title') }}
        </dt>
        <dd>
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{ discountDetails.discountRate }}%</span>
        </dd>
      </div>
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_title') }}
        </dt>
        <dd>
          <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
            <safe-html
              :html="
                $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_msg')
              "
            />
          </p>
          <div v-if="paidYn" class="mt-8">
            <st-table :columns="columns" :data="priceList">
              <template #row="{ row: item }">
                <st-tr>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <store-display-price-info
                      :flagImage="flagImages[item.displayCurrencyCode]"
                      :currencyCode="item.displayCurrencyCode"
                      :originalPriceValue="item.displayPrice"
                      :displayPriceValue="
                        displayPriceAfterDiscount(
                          item.salesPrice,
                          discountDetails.discountRate ?? 0,
                          item.displayCurrencyCode,
                          getExchangeInfo(item.displayCurrencyCode)?.exchangeRate,
                        )
                      "
                      :exchangeInfo="getExchangeInfo(item.displayCurrencyCode)"
                      :currencyOptions="{ currency: item.displayCurrencyCode }"
                    />
                  </st-td>
                  <st-td
                    class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                  >
                    <payment-price-info
                      :currencyCode="item.salesCurrencyCode!"
                      :originalPriceValue="item.salesPrice"
                      :paymentPriceValue="
                        paymentPriceAfterDiscount(
                          item.salesPrice,
                          discountDetails?.discountRate ?? 0,
                          item.salesCurrencyCode ?? CurrencyCode.Usd
                        )
                      "
                    />
                  </st-td>
                </st-tr>
              </template>
            </st-table>
          </div>
          <div v-else class="mt-8 text-md font-bold leading-lg text-on-surface-elevation-2">
            {{
              $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg')
            }}
          </div>
        </dd>
      </div>
      <div class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_title') }}
        </dt>
        <dd>
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
            discountDetails.salesLimitYn === Confirmation.YES
              ? $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_enable_msg')
              : $t(
                'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_disable_msg'
              )
          }}</span>
        </dd>
      </div>
      <div v-if="discountDetails.salesLimitYn === Confirmation.YES" class="flex flex-col gap-4">
        <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_title')
          }}
        </dt>
        <dd class="flex gap-8 items-center">
          <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{ formatNumberMultipleWithCommas(discountDetails.salesLimitCount || 0) }}
            {{
              $t(
                'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_unit_msg'
              )
            }}</span>
          <span class="text-md font-medium leading-lg text-on-surface-elevation-3">{{
            t(
              'studio.prj_prod.this_prod.prod_mgmt_discount_this_discount_details_limited_sales_remaining_quantity_msg',
              {
                remainingNum:
                  discountDetails.salesRemainCount === null ||
                  discountDetails.salesRemainCount === undefined
                    ? '-'
                    : formatNumberMultipleWithCommas(discountDetails.salesRemainCount)
              }
            )
          }}</span>
        </dd>
      </div>
    </dl>
  </st-box>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as ImageAssets from '@/assets/images/selling-price';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import PaymentPriceInfo from '@/components/selling-price/payment-price-info.vue';
import StoreDisplayPriceInfo from '@/components/selling-price/store-display-price-info.vue';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductDiscountDetailResponse } from '@/types/product-discount/product-discount-response.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';
import type { Column } from '@/types/table.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { formatDateTime, getUtcTimezone } from '@/utils/date.util';
import { displayPriceAfterDiscount, paymentPriceAfterDiscount } from '@/utils/price.util';

const props = defineProps<{
  discountDetails: ProductDiscountDetailResponse;
  paidYn: boolean;
  priceList: PriceListType[];
  defaultPrice: number;
  exchangeRates?: CurrencyInfoModel[];
}>();

const { t } = useI18n();

const flagImages = ref<Record<string, string>>(ImageAssets);

const columns: Column[] = [
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_displayed_price_header1'
    ),
    width: '50%'
  },
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_checkout_price_header2'
    ),
    width: 'auto'
  }
];

const getExchangeInfo = (currencyCode: string) => {
  return props.exchangeRates?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const utcTimezone = getUtcTimezone();
</script>
