<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <st-box>
        <div
          v-if="!paidYn"
          class="rounded-2xl bg-[#FFE7E7] mb-32 py-8 px-16 flex gap-4 items-start"
        >
          <s-icon icon="ic-v2-state-info-circle-line" size="xl" class="mt-[.3rem]" />
          <p class="text-sm leading-md font-medium text-on-surface-elevation-2">
            {{
              $t(
                'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_guide'
              )
            }}
          </p>
        </div>
        <st-form-title
          :formTitle="
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_name_title')
          "
          required
        />
        <input-text
          :rules="{
            required: $t(
              'studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_name_required_val_msg1'
            ),
            max_length: {
              length: 30,
              message: $t('studio.common.def_key.exceed', { length: 30 })
            }
          }"
          :placeholder="
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_name_place_holder')
          "
          :count="0"
          maxLength="30"
          countable
          name="name"
          :allowInputMaxLength="false"
        />
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.prod_mgmt_discount_register_start_date_title')"
          required
          class="mt-32"
        />
        <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
          <safe-html
            :html="$t('studio.event_period_settings.sel_discount_immediate_apply.possible_delay_msg')"
          />
        </p>
        <div class="flex flex-col gap-8 pt-8">
          <radio-group
            name="startDateType"
            :options="START_DATE_OPTIONS"
            :optionProps="{
              size: 'sm',
              align: 'middle'
            }"
          />
          <div v-show="values.startDateType === START_DATE_TYPE.SELECT">
            <datetime-picker
              name="startDate"
              :disabledDates="disabledStartDates"
              showUtc
              @update:modelValue="changeStartDate"
            />
          </div>
        </div>
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.prod_mgmt_discount_register_end_date_title')"
          required
          class="mt-32"
        />
        <datetime-picker
          showUtc
          name="endDate"
          :disabledDates="disabledEndDates"
          @update:modelValue="changeEndDate"
        />
        <p
          v-if="periodError || periodServerError"
          class="flex items-start gap-[.2rem] mt-[.4rem] text-error text-xs leading-xs font-regular"
        >
          <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" class="flex h-16 mt-[.1rem]" />
          {{ periodError || periodServerError }}
        </p>
        <st-form-title
          :formTitle="
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_title')
          "
          required
          class="mt-32"
        />
        <div class="flex gap-8 items-baseline">
          <div class="">
            <input-text
              size="sm"
              variant="outline"
              placeholder="0"
              containerClass="w-[16rem]"
              inputClass="text-right"
              :rules="{
                required: $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_req_val_msg1'
                )
              }"
              name="discountRate"
              :min="1"
              :max="100"
              :type="InputTextTypes.Number"
              @update:modelValue="changeDiscountRate"
            >
              <template #right>
                <span class="text-md font-medium leading-lg text-on-surface-elevation-3 absolute right-[-3.2rem]">%</span>
              </template>
            </input-text>
          </div>
        </div>
        <st-form-title
          :formTitle="
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_title')
          "
          class="mt-32"
        />
        <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
          <safe-html
            :html="$t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_msg')"
          />
        </p>
        <div v-if="paidYn" class="mt-8">
          <st-table :columns="columns" :data="priceList">
            <template #row="{ row: item }">
              <st-tr>
                <st-td
                  class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                >
                  <store-display-price-info
                    :flagImage="flagImages[item.displayCurrencyCode]"
                    :currencyCode="item.displayCurrencyCode"
                    :originalPriceValue="
                      values.discountRate && values.discountRate > 0 ? item.displayPrice : 0
                    "
                    :displayPriceValue="
                      displayPriceAfterDiscount(
                        item.salesPrice,
                        values.discountRate ?? 0,
                        item.displayCurrencyCode as CurrencyCode,
                        getExchangeInfo(item.displayCurrencyCode)?.exchangeRate,
                      )
                    "
                  />
                </st-td>
                <st-td
                  class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                >
                  <payment-price-info
                    :currencyCode="
                      item.displayCurrencyCode !== CurrencyCode.Krw
                        ? CurrencyCode.Usd
                        : CurrencyCode.Krw
                    "
                    :originalPriceValue="
                      values.discountRate && values.discountRate > 0 ? item.salesPrice : 0
                    "
                    :paymentPriceValue="
                      paymentPriceAfterDiscount(
                        item.salesPrice,
                        values.discountRate ?? 0,
                        item.salesCurrencyCode ?? CurrencyCode.Usd
                      )
                    "
                  />
                </st-td>
              </st-tr>
            </template>
          </st-table>
        </div>
        <div v-else class="mt-8 text-md font-bold leading-lg text-on-surface-elevation-2">
          {{
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg')
          }}
        </div>
        <st-form-title
          :formTitle="
            $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_title')
          "
          required
          class="mt-32"
        />
        <radio-group
          name="isLimitedSale"
          :options="LIMIT_QUANTITY_DISCOUNT_SALE_OPTIONS"
          class="flex gap-24 pt-8"
          :optionProps="{
            size: 'sm',
            align: 'middle'
          }"
        />
        <template v-if="values.isLimitedSale === COMMON_RADIO_TYPE.USED">
          <st-form-title
            :formTitle="
              $t(
                'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_title'
              )
            "
            required
            class="mt-32"
          />
          <div class="flex gap-8 items-baseline">
            <div class="w-[16rem]">
              <input-text
                :isFormatNumber="true"
                size="sm"
                variant="outline"
                :placeholder="`${formatNumberMultipleWithCommas(
                  LIMITED_QUANTITY_RANGE[0]
                )}~${formatNumberMultipleWithCommas(LIMITED_QUANTITY_RANGE[1])}`"
                inputClass="text-right"
                name="limitedQuantity"
                :rules="{
                  required: $t('studio.discount.register_sales_quantity_req_msg'),
                  regex: {
                    regex: ONLY_NUMBER_WITH_COMMA_REGEX,
                    message: $t('studio.common.def_key.number_only_y')
                  },
                  between: {
                    betweenParams: [
                      LIMITED_QUANTITY_RANGE[0].toString(),
                      LIMITED_QUANTITY_RANGE[1].toString()
                    ],
                    message: $t(
                      'studio.group.collection.discount_registration_limited_quantity_msg1'
                    )
                  }
                }"
                maxLength="9"
                :countable="false"
                :allowInputMaxLength="false"
                :pattern="ONLY_NUMBER_WITH_COMMA_REGEX"
              />
            </div>
            <span class="text-md font-medium leading-lg text-on-surface-elevation-3">{{
              $t(
                'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_unit_msg'
              )
            }}</span>
          </div>
        </template>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="onSubmit">
          {{ $t('studio.common.live_apply_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        v-for="(guide, idx) in showGuides"
        :key="guide.title + idx"
        :rightWingTitle="guide.title"
        :numberValue="`0${idx + 1}`"
        :class="{
          'mt-24': idx > 0
        }"
      >
        <safe-html :html="guide.content" />
      </right-wing-item>
    </right-wing>
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { createProductDiscountApi, updateProductDiscountApi } from '@/apis/product-discount.api';
import * as ImageAssets from '@/assets/images/selling-price';
import LiveConfirmDialog from '@/components/app/dialog/live-confirm-dialog.vue';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import PaymentPriceInfo from '@/components/selling-price/payment-price-info.vue';
import StoreDisplayPriceInfo from '@/components/selling-price/store-display-price-info.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { useProduct } from '@/composables/useProduct';
import { COMMON_RADIO_TYPE, START_DATE_TYPE } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { ONLY_NUMBER_WITH_COMMA_REGEX } from '@/constants/regex.const';
import { MIN_VALUE_PRICE_INPUT, MIN_VALUE_PRICE_INPUT_KRW } from '@/constants/selling-price.const';
import { PRODUCT_DISCOUNT_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { InputTextTypes, RuleNames } from '@/enums/validation.enum';
import type { ErrorResponse } from '@/types/common/common.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { DiscountCurrencies } from '@/types/product-discount/product-discount-model.type';
import type { CreateUpdateProductDiscountRequest } from '@/types/product-discount/product-discount-request.type';
import type { ProductDiscountDetailResponse } from '@/types/product-discount/product-discount-response.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';
import type { Column } from '@/types/table.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo } from '@/utils/common.util';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import {
  displayPriceAfterDiscount,
  getDiscountCurrencies,
  paymentPriceAfterDiscount
} from '@/utils/price.util';
import { generateErrorMsg } from '@/utils/validation.util';

const LIMITED_QUANTITY_RANGE = [1, 9999999];

const { t } = useI18n();

const { checkProductPermission } = useApp();
const route = useRoute();
const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

interface DiscountForm {
  name: string;
  startDate: Date;
  startDateType: string;
  endDate: Date;
  endHour: number;
  endMinute: number;
  discountRate: number | null;
  isLimitedSale: string;
  limitedQuantity: string | null;
}

const { discountDetails, priceList, exchangeRates } = defineProps<{
  discountDetails?: ProductDiscountDetailResponse;
  paidYn: boolean;
  priceList: PriceListType[];
  defaultPrice: number;
  exchangeRates?: CurrencyInfoModel[];
}>();

const flagImages = ref<Record<string, string>>(ImageAssets);
const periodServerError = ref<string>('');
const underAllowPrice = ref(false);

const columns: Column[] = [
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_displayed_price_header1'
    ),
    width: '50%'
  },
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_checkout_price_header2'
    ),
    width: 'auto'
  }
];

const showGuides = [
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide1'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide1_1')
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide2'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide2_1')
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide3'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide3_1')
  }
];

const defaultStartDate = DateTime.local().plus({ minutes: 1 });
const defaultEndDate = DateTime.local().plus({ days: 7, minutes: 1 });

const { handleSubmit, setValues, values, setErrors } = useForm<DiscountForm>({
  initialValues: {
    name: '',
    startDate: defaultStartDate.toJSDate(),
    startDateType: START_DATE_TYPE.NOW,
    endDate: defaultEndDate.toJSDate(),
    discountRate: null,
    isLimitedSale: COMMON_RADIO_TYPE.UNUSED,
    limitedQuantity: null
  }
});

const periodError = computed(() => {
  const startDate = DateTime.fromJSDate(values.startDate).set({ second: 0, millisecond: 0 });
  const endDate = DateTime.fromJSDate(values.endDate).set({ second: 0, millisecond: 0 });
  const currentDate = DateTime.local();

  if (values.startDateType === START_DATE_TYPE.SELECT) {
    if (startDate <= currentDate) {
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_register_reset_time_val_msg2');
    }
  }

  if (startDate >= endDate) {
    return t('studio.prj_prod.this_prod.prod_mgmt_discount_register_end_date_invalid_val_msg3');
  }

  return '';
});

const START_DATE_OPTIONS = [
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_start_date_immed_apply'),
    value: START_DATE_TYPE.NOW
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_start_date_schedule_apply'),
    value: START_DATE_TYPE.SELECT
  }
];

const LIMIT_QUANTITY_DISCOUNT_SALE_OPTIONS = [
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_enable_msg'),
    value: COMMON_RADIO_TYPE.USED
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_disable_msg'),
    value: COMMON_RADIO_TYPE.UNUSED
  }
];

const today = DateTime.local().startOf('day').toJSDate();

const disabledStartDates = {
  to: today
};

const disabledEndDates = {
  to: today
};

const changeStartDate = () => {
  periodServerError.value = '';
  const startDate = DateTime.fromJSDate(values.startDate).set({ second: 0, millisecond: 0 });
  const endDate = DateTime.fromJSDate(values.endDate).set({ second: 0, millisecond: 0 });
  if (startDate >= endDate) {
    setValues({
      endDate: startDate.plus({ days: 1 }).toJSDate()
    });
  }
};

const changeEndDate = () => {
  periodServerError.value = '';
};

const getExchangeInfo = (currencyCode: string) => {
  return exchangeRates?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const checkUnderAllowPrice = () => {
  const discountCurrencies = getDiscountCurrencies(priceList, values.discountRate, exchangeRates);
  underAllowPrice.value = discountCurrencies.some(
    (currency: DiscountCurrencies) => {
      const minValue = currency.salesCurrencyCode === CurrencyCode.Krw ? MIN_VALUE_PRICE_INPUT_KRW : MIN_VALUE_PRICE_INPUT;
      if (currency.salesPrice < minValue && currency.salesPrice !== 0) {
        return true;
      }

      return false;
    }
  );

  if (underAllowPrice.value) {
    setTimeout(() => {
      const errorMessage = generateErrorMsg(
        'studio.discount.discount_rate_min_payment_range_val_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ discountRate: errorMessage });
    });
  }
};

const changeDiscountRate = () => {
  checkUnderAllowPrice();
};

const onSubmit = handleSubmit(async (value: DiscountForm) => {
  if (!(await checkProductPermission())) {
    await redirectTo(`${PRODUCT_DISCOUNT_PAGE_URL}`);
    return;
  }

  if (periodError.value || periodServerError.value) {
    return;
  }

  checkUnderAllowPrice();
  if (underAllowPrice.value) {
    return;
  }

  const result = await showDialog({
    component: shallowRef(LiveConfirmDialog),
    severity: 'info'
  });

  if (result) {
    const { productId } = route.params;

    const params: CreateUpdateProductDiscountRequest = {
      discountName: value.name,
      discountRate: Number(value.discountRate),
      salesLimitYn:
        value.isLimitedSale === COMMON_RADIO_TYPE.USED ? Confirmation.YES : Confirmation.NO,
      salesLimitCount:
        value.isLimitedSale === COMMON_RADIO_TYPE.USED
          ? value.limitedQuantity
            ? Number(value.limitedQuantity.replace(/,/g, ''))
            : 0
          : null,
      startAt:
        value.startDateType === START_DATE_TYPE.NOW
          ? DateTime.local().toMillis()
          : DateTime.fromJSDate(value.startDate).toMillis(),
      endAt: DateTime.fromJSDate(value.endDate)
        .set({ hour: value.endHour, minute: value.endMinute })
        .toMillis(),
      discountCurrencies: getDiscountCurrencies(priceList, values.discountRate, exchangeRates)
    };

    try {
      const response = discountDetails?.discountNo
        ? await updateProductDiscountApi(Number(productId), discountDetails?.discountNo, params)
        : await createProductDiscountApi(Number(productId), params);

      if (response) {
        showCompleteSaveDialog();
      } else {
        showAlert({
          content: t(COMMON_ERROR_MESSAGE_KEY),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });
      }
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.PRODUCT_DISCOUNT_PERIOD_DUPLICATED) {
        periodServerError.value = t(
          'studio.prj_prod.this_prod.prod_mgmt_discount_register_dup_period_val_msg1'
        );
      } else if (errorCode === STATUS_CODE.PRODUCT_DISCOUNT_PERIOD_IS_PAST) {
        periodServerError.value = t(
          'studio.prj_prod.this_prod.prod_mgmt_discount_register_reset_time_val_msg2'
        );
      } else if (
        errorCode !== STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS &&
        errorCode !== STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS &&
        errorCode !== STATUS_CODE.READ_WRITE_ACCESS_DENIED
      ) {
        await throwContentError(COMMON_ERROR_MESSAGE_KEY);
      }
    }
  }
});

const init = async () => {
  if (discountDetails?.discountNo) {
    setValues({
      name: discountDetails.discountName,
      startDate: discountDetails.startAt
        ? new Date(discountDetails.startAt)
        : defaultStartDate.toJSDate(),
      startDateType: discountDetails.startAt ? START_DATE_TYPE.SELECT : START_DATE_TYPE.NOW,
      endDate: discountDetails.endAt ? new Date(discountDetails.endAt) : defaultEndDate.toJSDate(),
      discountRate: discountDetails.discountRate,
      isLimitedSale:
        discountDetails.salesLimitYn === Confirmation.YES
          ? COMMON_RADIO_TYPE.USED
          : COMMON_RADIO_TYPE.UNUSED,
      limitedQuantity: discountDetails.salesLimitCount
        ? formatNumberMultipleWithCommas(discountDetails.salesLimitCount)
        : null
    });
  }
};

init();
</script>
